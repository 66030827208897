.DayPicker {
    box-shadow: none !important;
    width: 100% !important;
}

.DateInput_input__focused {
    border-bottom: 2px solid #ea1f59 !important;
}

.CalendarDay {
    border: none !important;
    padding: 0 !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    color: #262626 !important;
    outline: none !important;
}

.CalendarDay__blocked_out_of_range {
    color: #cccccc !important;
    text-decoration: line-through !important;
}

.CalendarDay__selected_start_no_selected_end {
    background-image: none !important;
    background-color: #ea1f59;
    border-radius: 50% !important;
}

.CalendarDay__selected {
    background-color: transparent !important;
    background-image: url("https://d292awxalydr86.cloudfront.net/Universal+icons/start-date.svg") !important;
    background-size: cover !important;
    color: #ffffff !important;
}

.CalendarDay__selected_end {
    background-image: url("https://d292awxalydr86.cloudfront.net/Universal+icons/end-date.svg") !important;
}

.CalendarDay__selected_span {
    background: #e5e5e5 !important;
    color: #696969 !important;
}

.DateInput_fangStroke {
    display: none;
}
